import { IMeasurement } from '@interfaces/measurements'
import React from 'react'
import { ReferenceArea } from 'recharts'

export interface ReferenceAreaForNullValuesProps {
  groupedNullIndexes?: number[][]
  data: IMeasurement[]
  ticks: (string | number)[]
}

const ReferenceAreaForNullValues = ({ groupedNullIndexes, data, ticks }: ReferenceAreaForNullValuesProps) => {
  const availableTick = ticks.length <= 2 ? ticks[ticks.length - 1] : ticks[3]
  const tickHeight = Number(availableTick) / 2

  if (groupedNullIndexes?.length) {
    return groupedNullIndexes.map((nullIndexes, i) => (
      <ReferenceArea
        key={i + nullIndexes[0] + 2}
        x1={data[nullIndexes[0] - 1]?.timestampStartOfPeriod}
        x2={data[nullIndexes[nullIndexes.length - 1] + 1]?.timestampStartOfPeriod}
        y1={tickHeight}
        y2={ticks[0]}
        stroke="rgba(141, 150, 141, 0.2)"
        ifOverflow="extendDomain"
      />
    ))
  } else return null
}

export default ReferenceAreaForNullValues

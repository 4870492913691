import { Stack, Skeleton } from '@mui/material'

const ConsumptionSwitchSkeleton = () => {
  return (
    <Stack direction="row" gap={2} minWidth={290} sx={{ background: '#FAFAFA' }} p={1.125} borderRadius={50}>
      <Skeleton width="33%" />
      <Skeleton width="33%" />
      <Skeleton width="33%" />
    </Stack>
  )
}

export default ConsumptionSwitchSkeleton

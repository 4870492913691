import { switchComponentBackground } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import TooltipButton from '@components/charts/tooltips/components/TooltipButton'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { DtpChartTooltipWrapperProps, CustomEvent } from '@interfaces/tooltip'
import { Box, Stack, styled, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import ComparisonTooltipContent from './components/TooltipContents/ComparisonTooltipContent'
import ConsumptionTooltipContent from './components/TooltipContents/ConsumptionTooltipContent'
import FeedInTooltipContent from './components/TooltipContents/FeedInTooltipContent'
import ProsumerTooltipContent from './components/TooltipContents/ProsumerTooltipContent'

const CustomBox = styled(Stack)({
  position: 'relative',
  border: `1px solid ${switchComponentBackground}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius.medium,
  padding: 24,
  pointerEvents: 'all',
  boxShadow: '0px 2.058px 1.934px 0px rgba(0, 0, 0, 0.0022)',
  minWidth: '310px',
  gap: '16px',
  [theme.breakpoints.up('md')]: {
    minWidth: '350px',
  },
})

const DtpChartTooltipWrapper = ({
  active,
  payload,
  unit,
  setTooltipOpen,
  chartType,
  aggregationType,
  handleOnDateChange,
  id,
  maxPower,
  mesurementsType,
  activeBar,
}: DtpChartTooltipWrapperProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)
  const handleClickOutside = (event: MouseEvent) => {
    const customEvent: CustomEvent = event as CustomEvent
    if (customEvent.target.nodeName === 'rect' && chartType === 'comparison') return
    ref.current &&
      !ref.current.contains(customEvent.target as Node) &&
      customEvent.target.nodeName !== 'path' &&
      setTooltipOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  if (!active || !payload?.length) return null

  const { payload: currentPayloadValue } = payload[0]
  const { timestampStartOfPeriod, description } = currentPayloadValue

  const selectedTime = dayjs(timestampStartOfPeriod)
  const selectedTimeInterval = selectedTime.add(1, 'hour')
  const dateFormatForMonth = aggregationType === 'MMM' || aggregationType === 'month' ? 'MMMM YYYY' : 'YYYY'

  const returnTooltipContent = () => {
    if (chartType === 'comparison')
      return (
        <ComparisonTooltipContent
          payload={currentPayloadValue}
          unit={unit}
          t={t}
          chartType={chartType}
          activeBar={activeBar}
          measurementsType={mesurementsType}
        />
      )
    else
      switch (mesurementsType) {
        case 'consumption':
          return (
            <ConsumptionTooltipContent
              payload={currentPayloadValue}
              unit={unit}
              t={t}
              maxPower={maxPower}
              chartType={chartType}
            />
          )
        case 'feedIn':
          return <FeedInTooltipContent payload={currentPayloadValue} unit={unit} t={t} chartType={chartType} />
        case 'prosumer':
        case 'balance':
          return <ProsumerTooltipContent payload={currentPayloadValue} unit={unit} t={t} chartType={chartType} />
      }
  }

  const TitleBasedOnChartType = () => {
    return (
      <Typography variant="body1" sx={{ margin: '0 0 5px 0' }}>
        {chartType === 'bar'
          ? dayjs(timestampStartOfPeriod).format(aggregationType === 'DD' ? 'DD MMM YYYY' : dateFormatForMonth)
          : chartType === 'comparison'
            ? dayjs(timestampStartOfPeriod).format(dateFormatForMonth)
            : `${selectedTime.format('HH:mm')} - ${selectedTimeInterval.format('HH:mm')} ${t('hour')}`}
      </Typography>
    )
  }
  return (
    <Box ref={ref} id={id}>
      <CustomBox>
        <TitleBasedOnChartType />
        {returnTooltipContent()}

        <Show when={!!description}>
          <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
            *{t(`power_consumption_page.${description}`)}
          </Typography>
        </Show>

        <Show when={chartType === 'bar' || chartType === 'comparison'}>
          <Box>
            <TooltipButton
              onClick={() => {
                handleOnDateChange?.(timestampStartOfPeriod)
                chartType === 'comparison' && setTooltipOpen(false)
              }}
              xAxisDate={aggregationType as chartAxisEnum}
            />
          </Box>
        </Show>
      </CustomBox>
    </Box>
  )
}

export default DtpChartTooltipWrapper

import { chartPallete } from '@assets/themes/palette'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { isNil } from '@helpers/functional'
import { IMeasurement, MaxPowerType } from '@interfaces/measurements'
import dayjs from 'dayjs'
import i18n from 'i18next'

const { t } = i18n

export const chartStyles = (breakpoint: boolean) => {
  return {
    fontSize: breakpoint ? 11 : 14,
    lineHeight: 21,
    stroke: chartPallete.area.axisColor,
    tickLine: false,
    tick: { stroke: 'transparent' },
    axisLine: { stroke: 'transparent' },
    fontFamily: 'Montserrat',
  }
}

export const formatXAxisDate = (value: string, index: number, xAxisDate: chartAxisEnum, isMobile: boolean) => {
  const dateFormat = isMobile ? 'DD MMM' : 'DD'
  if (xAxisDate === chartAxisEnum.HOUR) {
    return dayjs(value).format('HH:mm')
  }
  if (xAxisDate === chartAxisEnum.MONTH) {
    return dayjs(value).format('MMM')
  }

  if (xAxisDate === chartAxisEnum.YEAR) {
    return dayjs(value).format('YYYY')
  }

  if (isMobile) {
    const visibleIndices = [2, 9, 16, 23]
    return visibleIndices.includes(index) ? dayjs(value).format(dateFormat) : ''
  }
  return dayjs(value).format(dateFormat)
}

export const xAxisInterval = (
  xAxisDate: chartAxisEnum,
  isMobile: boolean,
  isTablet: boolean,
  isWeb: boolean
): number | undefined => {
  if (xAxisDate === chartAxisEnum.MONTH) {
    return 0
  }

  if (xAxisDate === chartAxisEnum.HOUR) {
    return isMobile ? 3 : 1
  }

  if (isWeb || isTablet) {
    return isTablet ? 1 : 0
  }

  return undefined
}

export const formatTooltipTimeInterval = (time: string, xAxisDate: chartAxisEnum): string => {
  const selectedTime = dayjs(time)

  switch (xAxisDate) {
    case chartAxisEnum.HOUR: {
      const selectedTimeInterval = selectedTime.add(1, 'hour')
      return `${selectedTime.format('HH:mm')} - ${selectedTimeInterval.format('HH:mm')} ${t('hour')}`
    }
    case chartAxisEnum.DAY:
      return selectedTime.format('DD MMM YYYY')
    case chartAxisEnum.MONTH:
      return selectedTime.format('MMMM YYYY')
    case chartAxisEnum.YEAR:
      return selectedTime.format('YYYY')
    default:
      throw new Error(`Unsupported xAxisDate: ${xAxisDate}`)
  }
}

export const addMaxPowerInMonth = (maxPowerPerMonth: MaxPowerType[], measurements: IMeasurement[]) => {
  return measurements.map((measurement) => {
    const startOfPeriod = measurement.timestampStartOfPeriod

    const matchingMaxPower = maxPowerPerMonth.find(({ timestamp }) => {
      switch (startOfPeriod.length) {
        case 10:
          return timestamp.startsWith(startOfPeriod)
        case 7:
          return timestamp.slice(0, 7) === startOfPeriod
        default:
          return dayjs(timestamp).format('YYYY-MM-DDTHH:00') === startOfPeriod
      }
    })

    return {
      ...measurement,
      maxPowerInMonth: matchingMaxPower?.maxPower ?? null,
    }
  })
}

export const addNoDataValues = (data: IMeasurement[], xAxisDate: chartAxisEnum, chartMidHeight: number) => {
  const isYearView = xAxisDate === chartAxisEnum.MONTH

  return data.map(({ maxPower, maxPowerInMonth, ...rest }) => {
    const graphProp = isYearView ? maxPowerInMonth : maxPower

    return {
      ...rest,
      noDataValue: isNil(graphProp) ? chartMidHeight : null,
      maxPower: maxPower ?? chartMidHeight,
      maxPowerInMonth: isYearView ? (maxPowerInMonth ?? chartMidHeight) : maxPowerInMonth,
    }
  })
}

import ChartLoader from '@components/ChartLoader/ChartLoader'
import DtpChartTooltipWrapper from '@components/DtpChartTooltip/DtpChartTooltipWrapper'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { handleTooltipOpenAndPositionCompare } from '@helpers/chartBarFunctions'
import { IMeasurement, MeasurmentsType } from '@interfaces/measurements'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { CustomizedBar } from './CustomBar'

export interface IComparationChartProps {
  data: (IMeasurement | undefined)[]
  measurementType: MeasurmentsType
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  aggregationType?: 'month' | 'year'
  handleOnDateChange?: CallableFunction
}

const ComparationChart = ({
  data = [],
  unit = 'kWh',
  isLoading = false,
  aggregationType = 'month',
  handleOnDateChange,
  measurementType,
}: IComparationChartProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const TOOLTIP_INITIAL_X = 1
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : -240
  const [tooltipPosition, setTooltipPosition] = useState({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [wrapperStylePositionTooltip, setWrapperStylePositionTooltip] = useState<{ left: number; top: number }>()
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))

  useEffect(() => {
    if (isLoading && tooltipPosition.x !== TOOLTIP_INITIAL_X)
      setTooltipPosition({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const onMouseClick = (e: {
    tooltipPosition: object
    forecast: string
    timestampStartOfPeriod: string
    x: number
    y: number
    width: number
    height: number
  }) => {
    return handleTooltipOpenAndPositionCompare({
      e,
      tooltipOpen,
      setTooltipOpen,
      setTooltipPosition,
      isSmallMobile,
      setWrapperStylePositionTooltip,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      viewportWidth,
    })
  }

  const tickFormatter = (value: string | number, index: number) => {
    const feedInValue = data[index]?.feedIn
    const balanceValue = data[index]?.balance
    const energyValue = data[index]?.energy
    const localStringPrefixSignForProsumer =
      (feedInValue && Number(value) > 0) || (balanceValue && Number(value) > 0) ? '+' : ''
    const localStringValue = Number(energyValue && Number(energyValue) > 0 ? energyValue : value).toLocaleString(
      'de-CH',
      {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )
    const isProsumer = (feedInValue && Number(feedInValue) !== 0) || (balanceValue && Number(balanceValue) !== 0)
    const hasValue = Number(energyValue ? energyValue : value) !== 0 ? true : false
    return isProsumer
      ? `${localStringPrefixSignForProsumer}${localStringValue} ${unit}`
      : hasValue
        ? `${localStringValue} ${unit}`
        : ''
  }

  if (isLoading) {
    return <ChartLoader sx={{ minHeight: 200 }} />
  }
  if (!data.length)
    return (
      <Box pb={4.5}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={165}
          textAlign="center"
          borderBottom="2px solid #BFC4BF"
        >
          <Typography color="#4F544F" variant="bodySmallEmphasized" width={isMobile ? '70%' : '50%'}>
            {t('power_consumption_page.error_message_no_data_available') +
              ', ' +
              t('power_consumption_page.comparison_no_for_combination')}
          </Typography>
        </Stack>
      </Box>
    )
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data} barSize={41}>
        <XAxis
          tickLine={false}
          dataKey={measurementType}
          padding="no-gap"
          fontSize={isMobile ? 14 : 16}
          fill="#BFC4BF"
          tickFormatter={tickFormatter}
          axisLine={false}
        />
        <YAxis
          domain={([dataMin, dataMax]) => {
            const minVal = dataMin === 0 || dataMin < 0 ? (dataMin > dataMax ? dataMax : dataMin) : 0
            const maxVal = dataMax === 0 || dataMax < 0 ? 0 : dataMax < dataMin ? dataMin : dataMax
            return [minVal, maxVal]
          }}
          hide={true}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <DtpChartTooltipWrapper
                handleOnDateChange={handleOnDateChange}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                chartType="comparison"
                aggregationType={aggregationType === 'month' ? chartAxisEnum.MONTH : chartAxisEnum.YEAR}
                id={`comparationTooltip-${aggregationType}`}
                mesurementsType={measurementType ?? 'consumption'}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
        />

        <ReferenceLine y={0} stroke="#BFC4BF" />

        <Bar
          activeBar={false}
          dataKey={measurementType === 'consumption' && data[0]?.energy ? 'energy' : measurementType}
          stackId="a"
          className="comparationBar"
          shape={<CustomizedBar aggregationType={aggregationType} isMobile={isMobile} />}
          onClick={onMouseClick}
        />

        <Bar
          dataKey="forecast"
          stackId="a"
          stroke="#B161A280"
          strokeDasharray="2 2"
          strokeWidth={1}
          fill="#B161A233"
          onClick={onMouseClick}
          cursor="pointer"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ComparationChart

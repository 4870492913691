import React from 'react'
import { ReferenceLine } from 'recharts'

import { ReferenceAreaForNullValuesProps } from './ReferenceAreaForNullValues'

const ReferenceLineForNullValues = (props: ReferenceAreaForNullValuesProps) => {
  const { groupedNullIndexes, data, ticks } = props

  const availableTick = ticks.length <= 2 ? ticks[ticks.length - 1] : ticks[3]
  const tickHeight = Number(availableTick) / 2

  if (groupedNullIndexes?.length) {
    const isLastIndex = groupedNullIndexes[groupedNullIndexes.length - 1][1] === data.length - 1

    return groupedNullIndexes.map((nullIndexes, i) => (
      <ReferenceLine
        key={i + nullIndexes[0] + 1}
        segment={[
          { x: data[nullIndexes[0] - 1]?.timestampStartOfPeriod, y: tickHeight },
          {
            x: data[nullIndexes[nullIndexes.length - 1] + (isLastIndex ? 0 : 1)]?.timestampStartOfPeriod,
            y: tickHeight,
          },
        ]}
        strokeDasharray={'5 5'}
        stroke="rgba(141, 150, 141, 0.2)"
        strokeWidth={4}
        ifOverflow="extendDomain"
      />
    ))
  } else return null
}

export default ReferenceLineForNullValues

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const MetaTags = () => {
  const { t } = useTranslation()

  const environment = process.env.REACT_APP_NODE_ENV

  const baseUrl =
    environment === 'production'
      ? 'https://dtp-selfservice.dtp.ckw.ch'
      : 'https://dtp-selfservice-preprod.dtp-dev.ckw.ch'

  return (
    <Helmet>
      <title>{t('head.title')}</title>
      <meta name="title" content={t('head.title')} />
      <meta name="description" content={t('head.description')} />

      {/*  Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={baseUrl} />
      <meta property="og:title" content={t('head.title')} />
      <meta property="og:description" content={t('head.description')} />
      <meta property="og:image" content="https://metatags.io/images/meta-tags.png" />

      {/*  Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={baseUrl} />
      <meta property="twitter:title" content={t('head.title')} />
      <meta property="twitter:description" content={t('head.description')} />
      <meta property="twitter:image" content="https://metatags.io/images/meta-tags.png" />
    </Helmet>
  )
}

export default MetaTags

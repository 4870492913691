import { CompareIcon } from '@assets/icons'
import ComparationChart from '@components/ComparationChart/ComparationChartNew'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import CompareCardSkeleton from '@components/Skeletons/CompareCardSkeleton'
import { getDefaultComparationFromContract, getDefaultMinMaxDateFromContract } from '@helpers/dateFunctions'
import useComparison from '@hooks/useComparison/useComparison'
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export interface ICompareCardProps {
  datePickerValue: string
  title: string
  aggregationType: 'month' | 'year'
  locationId?: string
  meterId?: string
  contractId?: string
  subtitle?: string
  contractStartDate?: string
  contractEndDate?: string
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  setActiveTab?: (activeTab: number) => void
  setSelectedFromConsumptionDetail?: (selectedFromConsumptionDetail: string) => void
}

const DtpCompareCard = ({
  datePickerValue,
  title,
  subtitle,
  unit = 'kWh',
  locationId,
  meterId,
  contractId,
  contractStartDate,
  contractEndDate,
  aggregationType,
  isLoading = false,
  setActiveTab,
  setSelectedFromConsumptionDetail,
}: ICompareCardProps) => {
  const [period1, setPeriod1] = useState<string>('')
  const [period2, setPeriod2] = useState<string>('')
  const { comparationData, isLoadingComparation, measurementType } = useComparison({
    datePickerValue,
    aggregationType,
    locationId,
    contractId,
    meterId,
    contractStartDate,
    contractEndDate,
    period1,
    period2,
  })
  const { minDate, maxDate } = getDefaultMinMaxDateFromContract(
    aggregationType,
    datePickerValue,
    contractEndDate ?? '',
    contractStartDate ?? '',
    'YYYY-MM-DD'
  )

  const consumptionDetailClick = (value: string, tab: number) => {
    setSelectedFromConsumptionDetail && setSelectedFromConsumptionDetail(value)
    setActiveTab && setActiveTab(tab)
  }
  const tooltipTabIndexYear = aggregationType === 'year' ? 2 : 0
  const tooltipTabIndex = aggregationType === 'month' ? 1 : tooltipTabIndexYear

  useEffect(() => {
    if (contractEndDate && contractStartDate && maxDate) {
      const { firstPeriod, secondPeriod } = getDefaultComparationFromContract(
        contractStartDate,
        contractEndDate,
        maxDate
      )
      setPeriod1(firstPeriod)
      setPeriod2(secondPeriod)
    }
  }, [contractEndDate, contractStartDate, maxDate, meterId])

  if (isLoading || isLoadingComparation) return <CompareCardSkeleton chartType={aggregationType} />

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: '1px solid #D5D8D5',
        borderRadius: '24px',

        px: { xs: 1, md: 6 },
        py: { xs: 2, md: 4 },
        overflow: 'visible',
      }}
    >
      <CardHeader
        sx={{ p: { xs: 1, md: 0 } }}
        title={
          <Typography sx={{ m: '0 0 8px 0' }} variant="heading4">
            {title}
          </Typography>
        }
        subheader={
          <Typography sx={{ m: '0 0 8px 0' }} variant="bodyRegularEmphasized">
            {subtitle}
          </Typography>
        }
      />
      <CardContent sx={{ paddingBottom: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ gap: { sm: 0, md: 1 }, '& div, & button': { width: '100%' } }}
          mb={3}
        >
          <DtpDatePicker
            value={period1}
            onChange={setPeriod1}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />

          <CompareIcon fontSize="large" />

          <DtpDatePicker
            value={period2}
            onChange={setPeriod2}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />
        </Stack>

        <ComparationChart
          data={comparationData}
          measurementType={measurementType}
          isLoading={isLoadingComparation}
          unit={unit}
          aggregationType={aggregationType}
          handleOnDateChange={(value: string) => consumptionDetailClick(value, tooltipTabIndex)}
        />
      </CardContent>
    </Card>
  )
}

export default DtpCompareCard

import { getAggregationMeasurements } from '@api/queries'
import ChartArea from '@components/ChartArea/ChartArea'
import ChartBar from '@components/ChartBar/ChartBar'
import { ConsumptionSum } from '@components/ConsumptionSum/ConsumptionSum'
import DtpDateButton from '@components/DtpDateButton/DtpDateButton'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import DtpDownloadCSV from '@components/DtpDownloadCSV/DtpDownloadCSV'
import { FeedInLabel } from '@components/FeedInLabel/FeedInLabel'
import { ProsumerLable } from '@components/ProsumerLable/ProsumerLable'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import { AggregationEnum } from '@enums/aggregationTypes'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { getAggregationsStartEndDate, getDefaultMinMaxDateFromContract } from '@helpers/dateFunctions'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { IAggregationMeasurementsProps, IMeasurementResponse } from '@interfaces/measurements'
import { Stack, Grid, Skeleton } from '@mui/material'
import { DateView } from '@mui/x-date-pickers'
import Show from '@src/ui/wrappers/Show/Show'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

import { mappedMeasurementsData, noDataBarValue } from './ChartControlUtils'

export type ExtendedDateView = DateView | 'years'

interface IChartControlProps {
  addOn: IAddOn
  contractStartDate?: string
  contractEndDate?: string
  chartType: ExtendedDateView
  startModalDate: string
  endModalDate: string
  handleModalOpen: () => void
  customAggregation?: boolean
  setActiveTab?: (activeTab: number) => void
  selectedFromConsumptionDetail?: string
  setSelectedFromConsumptionDetail?: (selectedFromConsumptionDetail: string) => void
  lastAvailableData?: string
  isLoading?: boolean
  aggregationType: AggregationEnum
  selectedFromComparison?: string
  setSelectedFromComparison?: CallableFunction
}

interface IChartComponents {
  [key: string]: {
    graph: React.ReactNode
    consumptionSum: React.ReactNode
    prosumerSum: React.ReactNode
    feedInSum: React.ReactNode
  }
}

const ChartControl = ({
  addOn,
  contractStartDate,
  contractEndDate,
  chartType,
  setActiveTab,
  startModalDate,
  endModalDate,
  customAggregation = false,
  handleModalOpen,
  selectedFromConsumptionDetail,
  setSelectedFromConsumptionDetail,
  lastAvailableData,
  isLoading,
  aggregationType,
  selectedFromComparison,
  setSelectedFromComparison,
}: IChartControlProps) => {
  const initialTooltipPosition = { x: 500, y: -80 }
  const dateFormat = 'YYYY-MM-DD'
  const [tooltipPosition, setTooltipPosition] = useState(initialTooltipPosition)
  const [isLoadingAggregation, setIsLoadingAggregation] = useState<boolean>(true)
  const [date, setDate] = useState<string>('')
  const { gpnr, meterId, locationId, contractId } = addOn
  const consumptionDetailClick = (value: string, tab: number) => {
    setSelectedFromConsumptionDetail && setSelectedFromConsumptionDetail(value)
    setActiveTab && setActiveTab(tab)
  }

  const { data: aggregationData, mutate: getAggregationMeasurementsData } = useMutation<
    IMeasurementResponse,
    Error,
    IAggregationMeasurementsProps
  >({
    mutationFn: getAggregationMeasurements,
    onSuccess: ({ body: aggregationData }) => {
      mappedMeasurementsData(aggregationData)
      noDataBarValue(aggregationData)
      setIsLoadingAggregation(false)
      return aggregationData
    },
    onError: () => setIsLoadingAggregation(false),
  })
  const { minDate, maxDate } = getDefaultMinMaxDateFromContract(
    chartType,
    lastAvailableData ?? '',
    contractEndDate ?? '',
    contractStartDate ?? '',
    dateFormat
  )

  const datePickerValue = selectedFromConsumptionDetail?.length ? selectedFromConsumptionDetail : maxDate
  const customAggregationNoDataLabel = customAggregation
    ? startModalDate
    : maxDate || aggregationData?.body.measurements[0].timestampStartOfPeriod
  const noDataLabel = date.length > 0 ? dayjs(date).format(dateFormat) : customAggregationNoDataLabel
  const type = aggregationData?.body.type

  const maxPowerPerMonth = aggregationData?.body.maxPowerPerMonth

  const handleOnDateChange = (date: string) => {
    setTooltipPosition(initialTooltipPosition)
    setDate(date)
  }

  const tooltipTabIndexYear = chartType === 'year' ? 1 : 0
  const tooltipTabIndex = chartType === 'years' ? 2 : tooltipTabIndexYear

  const graphParams = {
    data: aggregationData?.body.measurements,
    unit: 'kWh' as 'kWh' | 'CHF',
    isLoading: isLoadingAggregation,
    noDataDateValue: noDataLabel,
    averageConsumption: aggregationData?.body.averageConsumption,
    handleOnDateChange: (value: string) => consumptionDetailClick(value, tooltipTabIndex),
    setTooltipPosition: setTooltipPosition,
    tooltipPosition: tooltipPosition,
    measurementType: type,
  }

  const labelParams = {
    data: aggregationData?.body,
    unit: 'kWh' as 'kWh' | 'CHF',
    isLoading: isLoadingAggregation,
  }

  const chartComponents: IChartComponents = {
    day: {
      graph: (
        <ChartArea
          data={aggregationData?.body.measurements}
          unit="kWh"
          noDataDateValue={noDataLabel}
          isLoading={isLoadingAggregation || isLoading}
          setTooltipPosition={setTooltipPosition}
          tooltipPosition={tooltipPosition}
          measurementType={type}
          maxPowerPerMonth={maxPowerPerMonth?.[0]}
        />
      ),
      consumptionSum: <ConsumptionSum {...labelParams} />,
      prosumerSum: <ProsumerLable {...labelParams} />,
      feedInSum: <FeedInLabel {...labelParams} />,
    },
    month: {
      graph: <ChartBar {...graphParams} xAxisDate={chartAxisEnum.DAY} maxPowerPerMonth={maxPowerPerMonth?.[0]} />,
      consumptionSum: <ConsumptionSum {...labelParams} />,
      prosumerSum: <ProsumerLable {...labelParams} />,
      feedInSum: <FeedInLabel {...labelParams} />,
    },
    year: {
      graph: <ChartBar {...graphParams} xAxisDate={chartAxisEnum.MONTH} />,
      consumptionSum: <ConsumptionSum {...labelParams} />,
      prosumerSum: <ProsumerLable {...labelParams} />,
      feedInSum: <FeedInLabel {...labelParams} />,
    },
    years: {
      graph: <ChartBar {...graphParams} xAxisDate={chartAxisEnum.YEAR} />,
      consumptionSum: <ConsumptionSum {...labelParams} />,
      prosumerSum: <ProsumerLable {...labelParams} />,
      feedInSum: <FeedInLabel {...labelParams} />,
    },
  }

  useEffect(() => {
    if (!date.length && !customAggregation) return

    setIsLoadingAggregation(true)
    const { startDate, endDate } = getAggregationsStartEndDate(
      date,
      chartType,
      customAggregation,
      startModalDate,
      endModalDate
    )

    getAggregationMeasurementsData({
      gpnr,
      meterId: meterId || undefined,
      aggregation: aggregationType,
      startDate,
      endDate,
      locationId: locationId || undefined,
      contractId: contractId || undefined,
    })
  }, [
    date,
    chartType,
    gpnr,
    meterId,
    startModalDate,
    getAggregationMeasurementsData,
    endModalDate,
    aggregationType,
    customAggregation,
    locationId,
    contractId,
  ])
  return (
    <Stack alignItems="flex-start" direction="column" gap={2}>
      <Grid container alignItems="center">
        <Grid item xs sm={5} order={{ xs: 1, sm: 1 }} mb={1}>
          <Show when={!!isLoading}>
            <Skeleton animation="wave" variant="rounded" width={210} height={40} />
          </Show>
          <Show when={!isLoading}>
            <Show when={customAggregation}>
              <DtpDateButton startDate={startModalDate} endDate={endModalDate} onClick={handleModalOpen} />
            </Show>
            {chartType !== 'years' && !customAggregation ? (
              <DtpDatePicker
                viewType={chartType}
                value={datePickerValue}
                onChange={handleOnDateChange}
                maxDate={maxDate}
                minDate={minDate}
                withPrevNext
                disabled={isLoadingAggregation}
                selectedFromComparison={selectedFromComparison}
                setSelectedFromComparison={setSelectedFromComparison}
              />
            ) : null}
          </Show>
        </Grid>
        <Grid item xs md={7} order={{ xs: 3, md: 2 }} container justifyContent={{ xs: 'center', md: 'end' }}>
          <Show when={type === 'feedIn'}>{chartComponents[chartType].feedInSum}</Show>
          <Show when={type === 'balance'}>{chartComponents[chartType].prosumerSum}</Show>
          <Show when={type === 'consumption'}>{chartComponents[chartType].consumptionSum}</Show>
          <Show when={isLoadingAggregation}>
            <ConsumptionSumSkeleton />
          </Show>
        </Grid>
        <Grid item xs={12} p={0} order={{ xs: 2, md: 3 }} height={300}>
          {chartComponents[chartType].graph}
        </Grid>
        <Grid item xs={12} order={{ xs: 4, sm: 4 }} display="flex" justifyContent={{ xs: 'start', sm: 'end' }}>
          <DtpDownloadCSV
            customAggregation={customAggregation}
            aggregation={aggregationType}
            chartType={chartType}
            startModalDate={startModalDate}
            endModalDate={endModalDate}
            date={date}
            addOn={addOn}
            timestampStartOfPeriod={aggregationData?.body?.measurements[0]?.timestampStartOfPeriod}
            isDisabled={!aggregationData?.body.measurements.length}
            isLoading={isLoading || isLoadingAggregation}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ChartControl

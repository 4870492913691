import { Typography } from '@mui/material'
import { Trans } from 'react-i18next'

type NoDataTooltipContentProps = {
  variant?: 'noValue' | 'noValueForPartialData'
}

const NoDataTooltipContent = ({ variant = 'noValue' }: NoDataTooltipContentProps) => {
  return (
    <Typography variant="bodyRegular" width={'280px'}>
      <Trans
        i18nKey={
          variant === 'noValue'
            ? 'power_consumption_page.error_message_no_data_available_for_time_period_description_new'
            : 'power_consumption_page.error_message_no_data_available_for_time_consumption_or_feedIn'
        }
      />
    </Typography>
  )
}

export default NoDataTooltipContent

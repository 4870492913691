import { TooltipContentProps } from '@interfaces/tooltip'
import { Divider, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

import TooltipContentRow from '../TooltipContentRow/TooltipContentRow'
import NoDataTooltipContent from './NoDataTooltipContent'

const FeedInTooltipContent = ({ payload, unit, t }: TooltipContentProps) => {
  const { feedIn, feedInMeasured } = payload
  const feedInTotal = !!feedIn && !!feedInMeasured ? Number(feedIn) + Number(feedInMeasured) : undefined
  const feedInColor = 'rgba(253, 195, 0, 1)'
  const feedInMeasuredColor = 'rgba(253, 195, 0, 0.5)'

  if (!feedIn) return <NoDataTooltipContent />

  return (
    <Stack gap={1}>
      <Show when={feedInTotal !== undefined && !!feedInMeasured}>
        <TooltipContentRow
          lableIconColor={feedInMeasuredColor}
          label={t('prosumer.prosumer_measured')}
          value={feedIn !== undefined ? Number(feedInMeasured).toFixed(2) : null}
          unit={unit}
          lableIconVariant="square"
        />
        <TooltipContentRow
          lableIconColor={feedInMeasuredColor}
          label={t('prosumer.prosumer_invoiced')}
          value={feedIn !== undefined ? Number(feedIn).toFixed(2) : null}
          unit={unit}
          lableIconVariant="square"
        />
        <Divider />
      </Show>
      <Show when={!!feedIn || feedInTotal !== undefined}>
        <TooltipContentRow
          lableIconColor={feedInColor}
          label={t('prosumer.prosumer_feed_in')}
          value={feedIn !== undefined ? Number(feedInTotal !== undefined ? feedInTotal : feedIn).toFixed(2) : null}
          unit={unit}
          lableIconVariant="square"
        />
      </Show>
    </Stack>
  )
}

export default FeedInTooltipContent

import { isNil } from '@helpers/functional'
import { DotProps } from 'recharts'

type DotType = DotProps & {
  payload: {
    [key: string]: string | number
  }
}

const Dot = (props: DotType) => {
  const { cx, cy, payload } = props

  if ((!isNil(payload.maxPower) && !isNil(payload.noDataValue)) || !isNil(payload.maxPowerInMonth)) {
    return <circle fill="transparent" />
  }
  return <circle cx={cx} cy={cy} r={4} fill="#6A716A" stroke="#FFFFFF" strokeWidth={0} />
}

export default Dot

import { DownloadIcon } from '@assets/icons'
import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import DtpInvoiceDetailCard from '@components/DtpInvoiceDetailCard/DtpInvoiceDetailCard'
import DtpInvoiceDetails from '@components/DtpInvoiceDetails/DtpInvoiceDetails'
import InvoiceDetailsPageSkeleton from '@components/Skeletons/InvoiceDetailsPageSkeleton'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { useDownloadInvoice } from '@hooks/useDownloadInvoice/useDownloadInvoice'
import useInvoiceContent from '@hooks/useInvoiceContent/useInvoiceContent'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { ArrowBack } from '@mui/icons-material'
import { Container, Typography, Button, Box, Stack, useTheme, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

const InvoiceDetails = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { allLocations, setShowExpiredContracts } = useLocationsData()

  const { invoiceContent, invoiceContentStatus, isLoading } = useInvoiceContent(id!)

  const [errorModalOpen, setErrorModalOpen] = useState(false)

  const { mutateAsync, isPending: isDownloadingInvoice } = useDownloadInvoice(
    () => setErrorModalOpen(false),
    () => setErrorModalOpen(true)
  )

  const handleDownloadPdfInvoice = async () => {
    try {
      if (id) {
        await mutateAsync(id)
      }
    } catch (error) {
      setErrorModalOpen(true)
    }
  }

  const billingPeriod = getFormattedBillingPeriod(
    invoiceContent?.billingPeriodFromDate,
    invoiceContent?.billingPeriodToDate
  )

  const invoiceContract = allLocations.find((contract) => contract.value === invoiceContent.contractId)
  const locationOrContract = allLocations.find(
    (contract) => contract.value === invoiceContract?.addOn?.locationOrContractId
  )
  const handleAnalyseConsumption = () => {
    if (dayjs(invoiceContract?.addOn?.supplyEndDate).isBefore(dayjs())) {
      setShowExpiredContracts(true)
    }

    navigate(t('navigation_url.energy_consumption'), {
      state: {
        tab: 3,
        contractId: locationOrContract?.value,
        billingPeriodFromDate: invoiceContent.billingPeriodFromDate,
        billingPeriodToDate: invoiceContent.billingPeriodToDate,
      },
    })
  }

  const isRedirectDisabled = !invoiceContent.billingPeriodFromDate || !invoiceContent.billingPeriodToDate

  if (isLoading) return <InvoiceDetailsPageSkeleton />

  return (
    <>
      <Container>
        <Box mb={{ xs: 3, md: 6 }}>
          <Button
            startIcon={<ArrowBack />}
            variant="text"
            size="small"
            disableRipple
            onClick={() => navigate(t('navigation_url.invoices'))}
            sx={{
              justifyContent: 'flex-start',
              p: 1,
              marginTop: { md: 6 },
              marginBottom: 1.2,
            }}
          >
            {t('navigation.navi_invoices')}
          </Button>
          <Typography variant="heading3">{`${t('invoices.invoice')} ${billingPeriod}`}</Typography>
          <Show when={!!invoiceContent.contractDescription}>
            <Typography variant="lead" sx={{ margin: 0, fontWeight: 400, color: theme.palette.text.secondary }}>
              {invoiceContent.contractDescription}
            </Typography>
          </Show>
        </Box>
        <Stack direction="row" justifyContent="space-between" columnGap={4}>
          <Show when={!isMobile}>
            <DtpInvoiceDetails {...invoiceContent} invoiceContentStatusCode={invoiceContentStatus.code} />
          </Show>
          <Box width={{ xs: '100%', md: 'auto' }}>
            <DtpInvoiceDetailCard {...invoiceContent} sx={{ maxWidth: 'unset' }} />
            <Show when={isMobile}>
              <DtpInvoiceDetails {...invoiceContent} invoiceContentStatusCode={invoiceContentStatus.code} />
            </Show>
            <Box my={3}>
              <Button startIcon={<DownloadIcon />} fullWidth size="small" onClick={handleDownloadPdfInvoice}>
                {t('invoices.invoice_download')}
              </Button>
              <Button
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                variant="outlined"
                onClick={handleAnalyseConsumption}
                disabled={isRedirectDisabled}
              >
                {t('invoices.invoice_analyse_energy_consumption')}
              </Button>
            </Box>
          </Box>
        </Stack>
      </Container>
      <DtpErrorModal
        errorTitle={t('http_errors.general_error_title')}
        errorDescription={t('http_errors.general_error_description_retry')}
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        isLoading={isDownloadingInvoice}
        onConfirm={handleDownloadPdfInvoice}
      />
    </>
  )
}

export default InvoiceDetails

import { chartPallete, prosumerPalette } from '@assets/themes/palette'
import ChartLabel from '@components/ChartLabel/ChartLabel'
import { ChartNoData } from '@components/ChartNoData/ChartNoData'
import DtpChartTooltipWrapper from '@components/DtpChartTooltip/DtpChartTooltipWrapper'
import MaxPowerDot from '@components/DtpMaxPower/MaxPowerDot'
import ChartSkeleton from '@components/Skeletons/ChartSkeleton'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { handleFormatXAxisHelper, intervalValueHelper, handleTooltipPositionAndOpen } from '@helpers/chartBarFunctions'
import { IMeasurement, MaxPowerType, MeasurmentsType } from '@interfaces/measurements'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
  ReferenceLine,
  ReferenceDot,
  Cell,
} from 'recharts'

interface IChartBarProps {
  data?: IMeasurement[]
  unit: 'kWh' | 'CHF'
  xAxisDate: chartAxisEnum
  averageConsumption?: string | number
  showBrush?: boolean
  noDataDateValue?: string
  isLoading?: boolean
  handleOnDateChange?: CallableFunction
  setTooltipPosition?: CallableFunction
  tooltipPosition: {
    x: number
    y: number
  }
  maxPowerPerMonth?: MaxPowerType
  measurementType?: MeasurmentsType
}

const ChartBar = ({
  data = [],
  showBrush = false,
  unit,
  xAxisDate,
  noDataDateValue,
  averageConsumption,
  handleOnDateChange,
  isLoading,
  setTooltipPosition,
  tooltipPosition,
  maxPowerPerMonth,
  measurementType,
}: IChartBarProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'))
  const TOOLTIP_INITIAL_X = isMobile ? 0 : 500
  const tooltipInitialYMobile = isMobile ? -60 : -80
  const TOOLTIP_INITIAL_Y = isSmallMobile ? 0 : tooltipInitialYMobile
  const wrapperStylePositionTooltip = { left: 40, top: -50 }
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const chartBarRef = useRef<HTMLDivElement | null>(null)
  const viewportWidth = chartBarRef?.current?.clientWidth as number

  const chartStyles = {
    fontSize: isMobile ? 11 : 14,
    lineHeight: 21,
    stroke: chartPallete.area.axisColor,
    tickLine: false,
    tick: { stroke: 'transparent' },
    axisLine: { stroke: 'transparent' },
    fontFamily: 'Montserrat',
  }

  const handleFormatXAxis = useCallback(
    (value: string, index: number) => {
      return handleFormatXAxisHelper(value, index, xAxisDate, isMobile)
    },
    [xAxisDate, isMobile]
  )
  const onMouseClick = (e: { x: number; y: number; width: number }) =>
    handleTooltipPositionAndOpen({
      e,
      isMobile,
      isTablet,
      isSmallMobile,
      viewportWidth,
      setTooltipPosition,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      setTooltipOpen,
      tooltipOpen,
    })

  const isFeedInGraph = measurementType === 'feedIn' || measurementType === 'prosumer' || measurementType === 'balance'
  const isConsumptionGraph = measurementType === 'consumption' || measurementType === 'balance'

  if (isLoading) return <ChartSkeleton chartAxis={xAxisDate} />

  if (!data.length)
    return (
      <ChartNoData
        currentDate={dayjs(noDataDateValue).format(xAxisDate === chartAxisEnum.DAY ? 'MMMM YYYY' : 'YYYY')}
      />
    )
  return (
    <ResponsiveContainer width="100%" height={296} ref={chartBarRef}>
      <BarChart
        data={data}
        margin={{
          top: 50,
          right: 0,
          left: 10,
          bottom: 10,
        }}
        // style={{ pointerEvents: 'none' }}
      >
        <CartesianGrid stroke={chartPallete.area.gridStroke} strokeWidth={1} strokeDasharray="0" vertical={false} />

        <XAxis
          dataKey="timestampStartOfPeriod"
          {...chartStyles}
          interval={intervalValueHelper(xAxisDate, isWeb, isTablet)}
          tickFormatter={handleFormatXAxis}
        >
          {xAxisDate === chartAxisEnum.DAY && (isWeb || isTablet) && (
            <Label style={{ fontSize: isMobile ? 11 : 14, fontWeight: 700 }} dy={13} position="insideBottomLeft">
              {dayjs(data[0].timestampStartOfPeriod).format('MMM')}
            </Label>
          )}
        </XAxis>

        <YAxis {...chartStyles} tickFormatter={(value) => value.toFixed(1)}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-140} dx={10}>
            {unit}
          </Label>
        </YAxis>

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <DtpChartTooltipWrapper
                setTooltipOpen={setTooltipOpen}
                mesurementsType={measurementType ?? 'consumption'}
                maxPower={maxPowerPerMonth}
                unit={unit}
                chartType="bar"
                aggregationType={xAxisDate}
                handleOnDateChange={handleOnDateChange}
                id="customBox"
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
          filterNull={false}
        />

        <Bar
          dataKey="amountHighRate"
          stackId="a"
          fill={chartPallete.bar.highRate.fill}
          stroke={chartPallete.bar.highRate.fill}
          strokeWidth={1}
          onClick={onMouseClick}
          cursor="pointer"
          pointerEvents="initial"
        />

        <Bar
          dataKey="amountLowRate"
          stackId="a"
          fill={chartPallete.bar.lowRate.fill}
          stroke={chartPallete.bar.lowRate.fill}
          strokeWidth={1}
          onClick={onMouseClick}
          cursor="pointer"
          pointerEvents="initial"
        />

        <Bar
          dataKey="forecast"
          stackId="a"
          fill={chartPallete.bar.forecast.fill}
          stroke={chartPallete.bar.forecast.stroke}
          strokeDasharray="2 2"
          strokeWidth={1}
          pointerEvents="none"
          cursor={'pointer'}
        />

        {isFeedInGraph && (
          <Bar
            dataKey="feedIn"
            stackId="a"
            fill={prosumerPalette.prosumerFeedIn}
            stroke={prosumerPalette.prosumerFeedIn}
            strokeWidth={1}
            onClick={onMouseClick}
            cursor={'pointer'}
          >
            {data.map((entry, index) =>
              entry.feedIn === null ? (
                <Cell
                  key={`cell-${index}`}
                  stroke={'#8D968D40'}
                  fill={'#8D968D0D'}
                  strokeWidth={1}
                  strokeDasharray={3}
                  strokeDashoffset={2}
                  height={-125}
                />
              ) : (
                <Cell key={`cell-${index}`} />
              )
            )}
          </Bar>
        )}

        {isConsumptionGraph && (
          <Bar
            dataKey="consumption"
            stackId={!isFeedInGraph ? 'a' : 'b'}
            fill={prosumerPalette.prosumerConsumption}
            stroke={prosumerPalette.prosumerConsumption}
            strokeWidth={1}
            onClick={onMouseClick}
            cursor={'pointer'}
          >
            {data.map((entry, index) =>
              entry.consumption === null ? (
                <Cell
                  key={`cell-${index}`}
                  stroke={'#8D968D40'}
                  fill={'#8D968D0D'}
                  strokeWidth={1}
                  strokeDasharray={3}
                  strokeDashoffset={2}
                  height={-125}
                />
              ) : (
                <Cell key={`cell-${index}`} />
              )
            )}
          </Bar>
        )}

        {isConsumptionGraph && (
          <Bar
            dataKey="energy"
            stackId="a"
            fill={prosumerPalette.prosumerConsumption}
            stroke={prosumerPalette.prosumerConsumption}
            strokeWidth={1}
            onClick={onMouseClick}
            cursor={'pointer'}
          />
        )}
        {maxPowerPerMonth?.maxPower && maxPowerPerMonth.timestamp && (
          <ReferenceLine
            segment={[
              { x: maxPowerPerMonth.timestamp, y: 0 },
              {
                x: maxPowerPerMonth.timestamp,
                y: maxPowerPerMonth.maxPower,
              },
            ]}
            strokeWidth={1}
            stroke={chartPallete.maxPower.bar}
          >
            <Label
              dy={14}
              position="right"
              value={t('consumption.maxPower')}
              content={
                <ChartLabel
                  color={theme.palette.text.secondary}
                  borderColor={chartPallete.maxPower.stroke}
                  font="GothamBook"
                />
              }
            />
          </ReferenceLine>
        )}

        {maxPowerPerMonth?.maxPower && maxPowerPerMonth.timestamp && (
          <ReferenceDot r={6} x={maxPowerPerMonth.timestamp} y={maxPowerPerMonth.maxPower} shape={<MaxPowerDot />} />
        )}

        <Bar
          dataKey={'noDataValue'}
          stackId="a"
          fill={chartPallete.area.gridStroke}
          stroke={chartPallete.area.gridStroke}
          strokeDasharray="0 2"
          strokeWidth={1}
          onClick={onMouseClick}
          cursor={'pointer'}
          pointerEvents="initial"
        />

        {showBrush && (
          // eslint-disable-next-line
          <Brush height={10} y={285} stroke={chartPallete.bar.highRate.fill} tickFormatter={(val) => (val = '')} />
        )}

        {averageConsumption && (
          <ReferenceLine
            y={averageConsumption}
            stroke={chartPallete.bar.averageConsumption.fill}
            strokeWidth={2}
            strokeDasharray="4 4"
          >
            <Label
              dy={10}
              value={`Ø ${averageConsumption}`}
              content={
                <ChartLabel
                  color={chartPallete.bar.averageConsumption.fill}
                  borderColor={chartPallete.bar.averageConsumption.stroke}
                />
              }
            />
          </ReferenceLine>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ChartBar

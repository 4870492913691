import { chartPallete } from '@assets/themes/palette'
import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { isNil } from '@helpers/functional'
import { Typography, Box } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation, Trans } from 'react-i18next'

import { formatTooltipTimeInterval } from '../DtpMaxPowerChart/utils'
import TooltipButton from './components/TooltipButton'
import TooltipWrapper from './components/TooltipWrapper'

type PayloadItem = {
  payload: {
    timestampStartOfPeriod: string
    maxPowerInMonth: string
    feedInMeasured?: string | number
    energy?: string
    energyChf?: string
    maxPower?: string
    reactivePower?: string
    description?: string
    noDataValue?: string
  }
}

type MaxPowerTooltip = {
  active?: boolean
  xAxisDate: chartAxisEnum
  payload?: PayloadItem[]
  unit: string
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOnDateChange?: (date: string) => void
}

export const MaxPowerTooltip = ({
  active,
  payload,
  unit,
  setTooltipOpen,
  xAxisDate,
  handleOnDateChange,
}: MaxPowerTooltip) => {
  const { t } = useTranslation()
  if (!active || !payload) return null

  const tooltipPayload = payload?.[0]?.payload

  const timeInterval = formatTooltipTimeInterval(tooltipPayload.timestampStartOfPeriod, xAxisDate)

  const showMaximumMaxPower = !isNil(tooltipPayload.maxPowerInMonth) && isNil(tooltipPayload.noDataValue)
  const showRegularMaxPower = isNil(tooltipPayload.maxPowerInMonth) && isNil(tooltipPayload.noDataValue)

  const showRedirectButton = xAxisDate !== chartAxisEnum.HOUR
  const hasDescription = !!tooltipPayload.description

  const hasValue =
    (!isNil(tooltipPayload.maxPower) || !isNil(tooltipPayload.maxPowerInMonth)) && isNil(tooltipPayload.noDataValue)

  const noDataAvailable =
    (isNil(tooltipPayload.maxPower) && isNil(tooltipPayload.maxPowerInMonth)) || !isNil(tooltipPayload.noDataValue)

  const handleRedirect = () => {
    handleOnDateChange?.(tooltipPayload.timestampStartOfPeriod)
    setTooltipOpen(false)
  }

  return (
    <TooltipWrapper setTooltipOpen={setTooltipOpen}>
      <Box mb={2}>
        <Typography variant="body1" sx={{ m: 0 }}>
          {timeInterval}
        </Typography>
      </Box>

      <Show when={hasValue}>
        <Show when={showMaximumMaxPower}>
          <ConsumptionItem
            label={t('consumption.maxPower')}
            value={tooltipPayload.maxPowerInMonth}
            unit={unit}
            color={chartPallete.maxPower.icon}
            componentVariant="ChartTooltip"
            showSquare
            maxPower
          />
        </Show>

        <Show when={showRegularMaxPower}>
          <ConsumptionItem
            label={t('power_consumption_page.peak_power')}
            value={tooltipPayload.maxPower}
            unit={unit}
            color={'#6A716A'}
            componentVariant="ChartTooltip"
            showCircle
          />
        </Show>
      </Show>

      <Show when={noDataAvailable}>
        <Typography variant="bodyRegular" maxWidth={'280px'} fontSize={16}>
          <Trans i18nKey="power_consumption_page.error_message_no_data_available_for_time_period_description_new" />
        </Typography>
      </Show>

      <Show when={hasDescription}>
        <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
          *{t(`power_consumption_page.${tooltipPayload.description}`)}
        </Typography>
      </Show>

      <Show when={showRedirectButton && hasValue}>
        <Box>
          <TooltipButton onClick={handleRedirect} xAxisDate={xAxisDate} />
        </Box>
      </Show>
    </TooltipWrapper>
  )
}

export default MaxPowerTooltip

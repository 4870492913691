import { isItNullValue } from '@helpers/chartAreaFunctions'
import { TooltipContentProps } from '@interfaces/tooltip'
import { Stack } from '@mui/material'

import TooltipContentRow from '../TooltipContentRow/TooltipContentRow'
import ConsumptionTooltipContent from './ConsumptionTooltipContent'
import FeedInTooltipContent from './FeedInTooltipContent'
import NoDataTooltipContent from './NoDataTooltipContent'

const ProsumerTooltipContent = (props: TooltipContentProps) => {
  const { payload, t, unit } = props
  const { feedIn, energy, consumption, energyMeasured, feedInMeasured } = payload

  if (!feedIn && !energy && !consumption) return <NoDataTooltipContent />
  return (
    <Stack gap={energyMeasured || feedInMeasured ? 2.5 : 1}>
      {isItNullValue(feedIn) ? (
        <TooltipContentRow
          lableIconColor=""
          unit={unit}
          value={null}
          label={t('prosumer.prosumer_feed_in')}
          lableIconVariant="noData"
        />
      ) : (
        <FeedInTooltipContent {...props} />
      )}
      {isItNullValue(energy) || isItNullValue(consumption) ? (
        <TooltipContentRow
          lableIconColor=""
          unit={unit}
          value={null}
          label={t('consumption.energy')}
          lableIconVariant="noData"
        />
      ) : (
        <ConsumptionTooltipContent {...props} />
      )}

      {(isItNullValue(energy) || isItNullValue(consumption) || isItNullValue(feedIn)) && (
        <NoDataTooltipContent variant="noValueForPartialData" />
      )}
    </Stack>
  )
}

export default ProsumerTooltipContent

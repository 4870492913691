import { CheckIcon, ClockIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { invoiceStatus } from '@enums/invoiceStatus'
import i18n from '@src/translation/config'

const iconStyles = {
  marginRight: 0.5,
  width: 22,
  height: 22,
  vericalAlign: 'middle',
  fill: 'transparent',
}

export const statusType = {
  [invoiceStatus.PAID]: {
    icon: <CheckIcon sx={{ ...iconStyles }} />,
    text: i18n.t('invoices.invoice_status_paid'),
    textColor: theme.palette.text.primary,
    bgColor: '#FDC300',
  },
  [invoiceStatus.OPEN]: {
    icon: <ClockIcon sx={{ ...iconStyles }} />,
    text: i18n.t('invoices.invoice_status_outstanding'),
    textColor: theme.palette.text.primary,
    bgColor: '#FDC300',
  },
  [invoiceStatus.UNKNOWN]: {
    icon: <CheckIcon sx={{ ...iconStyles }} />,
    text: i18n.t('invoices.invoice_status_unknown'),
    textColor: theme.palette.text.primary,
    bgColor: 'transparent',
  },
}

import { Box, Stack, styled, Typography } from '@mui/material'

const StyledSquare = styled(Box)({
  margin: 0,
  padding: 0,
  width: 9,
  height: 9,
  borderRadius: 2,
})

const StyledCircle = styled(Box)({
  margin: 0,
  padding: 0,
  width: 9,
  height: 9,
  borderRadius: '50%',
  border: '1px solid',
})

type TooltipContentRowProps = {
  label: string
  value: string | null
  unit: string
  lableIconColor: string
  lableIconVariant?: 'square' | 'circle' | 'noData' | 'forecast' | 'maxPower'
}

const TooltipContentRow = ({ lableIconColor, label, value, unit, lableIconVariant }: TooltipContentRowProps) => {
  const returnLableIcon = () => {
    switch (lableIconVariant) {
      case 'square':
        return <StyledSquare sx={{ backgroundColor: lableIconColor }} />
      case 'circle':
        return <StyledCircle sx={{ borderColor: lableIconColor, backgroundColor: lableIconColor }} />
      case 'maxPower':
        return (
          <StyledCircle
            sx={{
              backgroundColor: '#EC6A08',
              outline: '1px solid #EC6A08',
              outlineOffset: '1px',
              border: '#EC6A08',
              width: 8,
              height: 8,
            }}
          />
        )
      case 'noData':
        return (
          <StyledSquare
            sx={{ backgroundColor: 'rgba(141, 150, 141, 0.05)', border: '1px dashed rgba(141, 150, 141, 0.5)' }}
          />
        )
      case 'forecast':
        return (
          <StyledSquare
            sx={{ backgroundColor: 'rgba(177, 97, 162, 0.2)', border: '1px dashed  rgba(177, 97, 162, 0.5)' }}
          />
        )
      default:
        return null
    }
  }

  const valueToLocaleString = Number(value).toLocaleString('de-CH', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const positionOfUnitAndValueBasedOnUnit =
    unit === 'CHF' ? `${unit} ${valueToLocaleString}` : `${valueToLocaleString} ${unit}`

  return (
    <Stack direction="row" alignItems={'center'} spacing={1} justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center" justifyContent={'flex-start'}>
        {lableIconVariant && returnLableIcon()}
        <Typography variant="bodyRegular" component="span">
          {label}
        </Typography>
      </Stack>
      <Typography variant="bodyRegular" component="span" justifyItems="flex-end" fontFamily="GothamMedium">
        {value !== null ? positionOfUnitAndValueBasedOnUnit : '-*'}
      </Typography>
    </Stack>
  )
}

export default TooltipContentRow

import { switchComponentBackground } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { Box, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

const PADDING_SIZE = 4
const GAP_SIZE = 4
const ITEM_HEIGHT = 34

type ToggleButtonProps = {
  switchOptions: { label?: string; value: string; icon?: React.ReactNode }[]
  itemWidth?: number
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    maxWidth: 'max-content',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    padding: 4,
    backgroundColor: switchComponentBackground,
    borderRadius: 50,
    gap: GAP_SIZE,
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      borderRadius: 50,
      '&.Mui-disabled': {
        border: 0,
      },
    },
  },
}))

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root': {
    zIndex: 1,
    minWidth: 24,
    height: ITEM_HEIGHT,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
    border: 0,
    borderRadius: 50,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      color: theme.palette.background.paper,
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 32,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },
    },
  },
}))

const SwitchBackgroundSelector = styled(Box)(() => ({
  position: 'absolute',
  left: PADDING_SIZE,
  bottom: PADDING_SIZE,
  zIndex: 0,
  height: ITEM_HEIGHT,
  '&.MuiBox-root': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    transition: 'transform 150ms cubic-bezier(0.33, 1, 0.68, 1)',
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
}))

const DtpSwitch = ({ switchOptions, itemWidth = 64 }: ToggleButtonProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(switchOptions[0].value)

  return (
    <Box position={'relative'}>
      <StyledToggleButtonGroup
        value={selectedOption}
        exclusive
        onChange={(e, value) => {
          value && setSelectedOption(value)
        }}
        aria-label="switch"
      >
        {switchOptions.map((option) => (
          <StyledToggleButton
            key={option.value}
            sx={{ width: itemWidth }}
            value={option.value}
            aria-label={option.value}
            disableRipple
          >
            {option.icon}
            <Typography
              sx={{
                color: selectedOption === option.value ? theme.palette.background.paper : 'initial',
                margin: option.label && option.icon ? '0 0 0 10px' : 0,
                transition: '.2s ease-in',
              }}
              variant="bodyRegularEmphasized"
            >
              {option.label}
            </Typography>
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <Box>
        <SwitchBackgroundSelector
          width={itemWidth}
          sx={{
            transform:
              selectedOption === switchOptions[1].value ? `translateX(${itemWidth + GAP_SIZE}px)` : 'translateX(0)',
          }}
        />
      </Box>
    </Box>
  )
}

export default DtpSwitch

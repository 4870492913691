import theme from '@assets/themes/theme'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CustomButton = styled(Button)({
  fontSize: '16px',
  letterSpacing: '0em',
  padding: 0,
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
})

type TooltipButtonProps = {
  onClick: () => void
  xAxisDate: chartAxisEnum
}

const TooltipButton = ({ onClick, xAxisDate }: TooltipButtonProps) => {
  const { t } = useTranslation()
  const translateForMonthAndDay =
    xAxisDate === chartAxisEnum.MONTH
      ? t('power_consumption_page.go_to_monthly_view')
      : t('power_consumption_page.go_to_daily_view')
  return (
    <CustomButton onClick={onClick} variant={'text'} endIcon={<ArrowForwardIcon />} disableTouchRipple>
      {xAxisDate === chartAxisEnum.YEAR ? t('power_consumption_page.go_to_yearly_view') : translateForMonthAndDay}
    </CustomButton>
  )
}

export default TooltipButton

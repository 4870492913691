import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ChartLabel from '@components/ChartLabel/ChartLabel'
import MaxPowerDot from '@components/DtpMaxPower/MaxPowerDot'
import { MaxPowerType } from '@interfaces/measurements'
import { TFunction } from 'i18next'
import { Label, ReferenceDot, ReferenceLine } from 'recharts'

interface MaxPowerReferenceLineProps {
  maxPowerAvailable?: boolean
  maxPowerPerMonth?: MaxPowerType
  min: number
  labelPlacement: number
  t: TFunction
}

const MaxPowerReferenceLine = ({
  maxPowerPerMonth,
  maxPowerAvailable,
  min,
  labelPlacement,
  t,
}: MaxPowerReferenceLineProps) => {
  if (maxPowerAvailable && maxPowerPerMonth?.maxPower && maxPowerPerMonth?.timestamp)
    return (
      <ReferenceLine
        segment={[
          { x: maxPowerPerMonth.timestamp, y: min },
          {
            x: maxPowerPerMonth.timestamp,
            y: maxPowerPerMonth.maxPower,
          },
        ]}
        strokeWidth={1}
        stroke={chartPallete.maxPower.bar}
        ifOverflow="extendDomain"
      >
        <Label
          dy={labelPlacement}
          position="insideTopRight"
          value={t('consumption.maxPower')}
          content={
            <ChartLabel
              color={theme.palette.text.secondary}
              borderColor={chartPallete.maxPower.stroke}
              font="GothamBook"
            />
          }
        />
        <ReferenceDot
          r={6}
          x={maxPowerPerMonth.timestamp}
          y={String(maxPowerPerMonth.maxPower)}
          shape={<MaxPowerDot />}
          ifOverflow="extendDomain"
        />
      </ReferenceLine>
    )
  return null
}

export default MaxPowerReferenceLine

import { isNil } from '@helpers/functional'
import { DotProps } from 'recharts'

type DotType = DotProps & {
  payload: {
    [key: string]: string | number
  }
}

const NoDataDot = (props: DotType) => {
  const { cx, cy, payload } = props

  if (isNil(payload.noDataValue)) {
    return <circle fill="transparent" />
  }
  return <circle cx={cx} cy={cy} r={4} fill="#8D968D1A" stroke="#8D968D80" strokeWidth={1} strokeDasharray={2} />
}

export default NoDataDot

import { AreachartIcon, BarchartIcon, BarchartWithLineIcon } from '@assets/icons'
import { AggregationEnum } from '@enums/aggregationTypes'
import { getAggregationType } from '@helpers/dateFunctions'
import dayjs from 'dayjs'
import i18n from 'i18next'

const { t } = i18n

export const switchOptions = [
  {
    label: t('power_consumption_page.energy_consumption_label'),
    value: 'consumption',
    icon: <BarchartIcon />,
  },
  {
    label: t('power_consumption_page.peak_power'),
    value: 'maxPower',
    icon: <AreachartIcon />,
  },
  {
    label: t('power_consumption_page.reactive_power'),
    value: 'reactivePower',
    icon: <BarchartWithLineIcon />,
  },
]

export const getAggregationsTimePeriod = (
  inputDate: string,
  aggregation: AggregationEnum,
  customAggregation?: boolean,
  customStartDate?: string,
  customEndDate?: string
) => {
  if (customAggregation && customStartDate?.length && customEndDate?.length) {
    const aggregation = getAggregationType(customStartDate, customEndDate)
    return { startDate: customStartDate, endDate: customEndDate, aggregation: aggregation }
  }

  switch (aggregation) {
    case AggregationEnum.HOUR:
      return {
        startDate: dayjs(inputDate).startOf('day').format('YYYY-MM-DD'),
        endDate: dayjs(inputDate).endOf('day').format('YYYY-MM-DD'),
        aggregation: AggregationEnum.HOUR,
      }
    case AggregationEnum.DAY:
      return {
        startDate: dayjs(inputDate).startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs(inputDate).endOf('month').format('YYYY-MM-DD'),
        aggregation: AggregationEnum.DAY,
      }

    case AggregationEnum.MONTH:
      return {
        startDate: dayjs(inputDate).startOf('year').format('YYYY-MM-DD'),
        endDate: dayjs(inputDate).endOf('year').format('YYYY-MM-DD'),
        aggregation: AggregationEnum.MONTH,
      }
    default:
      return {
        startDate: '',
        endDate: '',
        aggregation: AggregationEnum.HOUR,
      }
  }
}

import { prosumerPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import { IFeedInAggregationMeasurement } from '@interfaces/measurements'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Paper, useMediaQuery, Typography, Button, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IFeedInLabelProps {
  data?: IFeedInAggregationMeasurement | null
  unit: 'CHF' | 'kWh'
  isLoading?: boolean
}

export const FeedInLabel = ({ data, unit, isLoading }: IFeedInLabelProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const feedInValue = data?.feedInSum ?? data?.sumFeedIn
  const prosumerFeedIn = feedInValue ? Number(feedInValue).toFixed(2) : '-'

  if (isLoading) return <ConsumptionSumSkeleton />

  return (
    <Paper
      variant={isMobile ? 'outlined' : 'elevation'}
      elevation={0}
      sx={{
        width: 'max-content',
        maxWidth: '360px',
        backgroundColor: 'transparent',
        borderRadius: 0,
        [theme.breakpoints.down('md')]: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          maxWidth: '100%',
          width: '100%',
          backgroundColor: theme.palette.grey[50],
          p: 1,
          mx: 0,
          borderRadius: 4,
        },
      }}
    >
      <ProsumerItem
        label={t('prosumer.prosumer_feed_in')}
        value={prosumerFeedIn}
        unit={unit}
        showSquare={true}
        color={prosumerPalette.prosumerFeedIn}
        componentVariant="ProsumerLable"
        showTooltip
        tooltipContent={
          <>
            <Typography sx={{ my: 0 }} color={theme.palette.text.primary} fontFamily="GothamBook" variant="bodySmall">
              {t('prosumer.prosumer_feed_in_explanation')}
            </Typography>
            <Link href="https://www.ckw.ch/energie/strom/ruecklieferverguetung" target="_blank" rel="noopener">
              <Button
                variant={'text'}
                startIcon={<ArrowForwardIcon />}
                sx={{
                  fontSize: '16px',
                  letterSpacing: '0em',
                  padding: 0,
                  marginTop: theme.spacing(2),
                  justifyContent: 'flex-start',
                  [theme.breakpoints.down('md')]: {
                    padding: 0,
                  },
                }}
              >
                {t('learn_more')}
              </Button>
            </Link>
          </>
        }
      />
    </Paper>
  )
}

import { getLastAvailableDayV2 } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { IMeasurementResponse } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'

export const useLastAvailableDayV2 = () => {
  const { activeAccount } = useAccounts()
  const { selectedItem } = useLocationsData()

  const gpnr = activeAccount.gpnr
  const meterId = selectedItem.addOn?.meterId
  const contractId = selectedItem.addOn?.contractId
  const locationId = selectedItem.addOn?.locationId

  const { data: lastAvailableDay, isLoading: isLoadingLastAvailableDay } = useQuery({
    queryKey: ['lastAvailableDay', gpnr, meterId, locationId, contractId],
    queryFn: () =>
      getLastAvailableDayV2({
        gpnr: gpnr,
        meterId: meterId ?? undefined,
        locationId: locationId ?? undefined,
        contractId: contractId ?? undefined,
      }),
    enabled: !!gpnr && ((!!contractId && !!meterId) || !!locationId),
    gcTime: 0,
    select: (data: IMeasurementResponse) => data?.body?.measurements[0]?.timestampStartOfPeriod,
  })

  return { lastAvailableDay, isLoadingLastAvailableDay }
}
